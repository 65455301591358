import http from "../http";
import { UploadComponent } from "@/utils/download-app";

export default {
  /**
   * 获取下载地址
   */
  async downloadUrl() {
    const host = window.location.hostname || "miletest.com";
    const protocol = document.location.protocol;
    // const domain = `${protocol}//api.btyseo.com/seoapi/domain-config/client/?domain=https://zz-seo-test.bsportstest.com`;
    const domain = `${protocol}//api.btyseo.com/seoapi/domain-config/client?domain=${protocol}//${host}`;
    const data = await http.get(domain);

    return data.data;
  },

  async uploadFingerReport(
    components: UploadComponent,
    hash: string,
    pid = "zz"
  ) {
    const domain = "https://ssapi.iballbet.com";
    const data = await http.post(`${domain}/ssapi/install/register`, {
      ...components,
      hash,
      pid,
    });
    return data;
  },
};
