export default {
  download: {
    install: "Install APP",
    goH5: "Do not install, experience the mobile version",
    tutorial: "See installation instructions",
    iosTitle: "IOS enterprise application adds trust",
    subTitle:
      "After installing the APP, please follow the steps below, otherwise it will not work properly",
  },
};
