import { createI18n } from "vue-i18n";
import zh from "./zh";
import en from "./en";
import vi from "./vi";

const userLang = navigator.language.toLowerCase();
let langCode = "zh";

switch (true) {
  case userLang.indexOf("vi") > -1:
    langCode = "vi";
    break;
  case userLang.indexOf("en") > -1:
    langCode = "en";
    break;
  default:
    langCode = "zh";
}

const i18n = createI18n({
  // 如果本地有语言标识就采用本地，没有就根据浏览器语言默认标识显示语言
  locale: langCode,
  legacy: false,
  messages: {
    zh,
    en,
    vi,
  },
});
export default i18n;
