export default {
  download: {
    install: "Tải APP",
    goH5: "Không cài đặt, trải nghiệm phiên bản di động",
    tutorial: "Xem hướng dẫn cài đặt",
    iosTitle: "Thêm ứng dụng doanh nghiệp IOS vào mục tin cậy",
    subTitle:
      "Sau khi cài đạt ứng dụng vui lòng làm theo thao tác dưới đây, nếu không ứng dụng không thể sừ dụng",
  },
};
