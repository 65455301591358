
import { defineComponent } from "vue";

const DynamicApp =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require(`@/apps/${process.env.VUE_APP_SCOPE}/App.vue`).default;

export default defineComponent({
  render() {
    return (
      <div id="app">
        <DynamicApp />
      </div>
    );
  },
});
